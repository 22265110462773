function Gift() {
  return (
    <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M4.7069 0.962963C4.08796 0.962963 3.58621 1.50188 3.58621 2.16667C3.58621 2.83145 4.08796 3.37037 4.7069 3.37037H5.82759V6.25926H1.12069C0.501752 6.25926 0 5.72032 0 5.05556L0 4.57407C0 3.90929 0.501752 3.37037 1.12069 3.37037H3.02935C2.81478 3.02609 2.68966 2.61207 2.68966 2.16667C2.68966 0.97005 3.59281 0 4.7069 0C5.34058 0 5.90606 0.313849 6.27586 0.804716C6.64566 0.313849 7.21114 0 7.84483 0C8.95894 0 9.86207 0.97005 9.86207 2.16667C9.86207 2.61207 9.73691 3.02609 9.52239 3.37037H11.8793C12.4982 3.37037 13 3.90929 13 4.57407V5.05556C13 5.72032 12.4982 6.25926 11.8793 6.25926H6.72414V3.37037H7.84483C8.46375 3.37037 8.96552 2.83145 8.96552 2.16667C8.96552 1.50188 8.46375 0.962963 7.84483 0.962963C7.22591 0.962963 6.72414 1.50188 6.72414 2.16667V3.37037H5.82759V2.16667C5.82759 1.50188 5.32582 0.962963 4.7069 0.962963Z' />
      <path d='M5.82759 7.22222H0.896552V11.5556C0.896552 12.3533 1.49865 13 2.24138 13H5.82759V7.22222Z' />
      <path d='M6.72414 7.22222V13H10.7586C11.5013 13 12.1034 12.3533 12.1034 11.5556V7.22222H6.72414Z' />
    </svg>
  );
}

export default Gift;
