function Jagged(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={props.className}
      viewBox='0 0 163 45'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
      style={props.style}
    >
      <path d='M21.5855 1.11438L5.41904 2.6871C2.34487 2.98617 0 5.57021 0 8.65891V40.203C0 41.5726 0.392525 43.3929 1.76216 43.3929C2.4131 43.3929 6.11981 44.002 8.30235 44.3765C8.64161 44.4347 8.98388 44.4643 9.3281 44.4643H14.0973L18.5027 43.9286L23.7892 43.3929H30.3844C30.6863 43.3929 30.9877 43.4156 31.2861 43.461L33.9139 43.8604C34.2123 43.9058 34.5137 43.9286 34.8156 43.9286H39.0467C39.447 43.9286 39.8463 43.9686 40.2386 44.0481L44.3452 44.8804C44.7375 44.9599 45.1368 45 45.537 45H47.5784H57.2703H66.0811H75.773L97.1986 44.4789C97.5985 44.4692 97.9965 44.4195 98.3865 44.3306L104.48 42.9413C104.724 42.8856 104.958 42.7907 105.172 42.6605C105.537 42.4387 105.956 42.3214 106.382 42.3214H112.778L120.708 43.3929H132.162L141.385 43.9027C141.697 43.9199 142.01 43.9127 142.321 43.8812L157.605 42.3324C160.669 42.022 163 39.4425 163 36.363V4.1489C163 3.2359 162.524 2.38902 161.744 1.9147C161.411 1.71222 161.037 1.58675 160.649 1.54746L155.951 1.07143L151.421 0.612292C150.919 0.561443 150.413 0.574065 149.914 0.649858L147.141 1.07143L143.598 1.93306C143.026 2.07217 142.436 2.12635 141.849 2.09386L133.043 1.60714L123.351 0.535714L118.065 0H96.9189L65.6995 1.05456C65.3671 1.06579 65.0362 1.10463 64.7102 1.1707L59.9135 2.14286L52.5166 2.64257C52.1621 2.66652 51.8061 2.65893 51.4529 2.61989L42.2919 1.60714H32.6L22.4701 1.09388C22.1751 1.07893 21.8794 1.08579 21.5855 1.11438Z' />
    </svg>
  );
}

export default Jagged;
